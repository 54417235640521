.container {
  height: 40px;
  width: 40px;
  z-index: 1000;
  margin-top: -40px;
  cursor: pointer;
}

.container:hover {
  background: #173f50;
}

.right {
  float: right;
}
