html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media screen and (max-width: 786px) {
  .st-50-50 > .hSelect {
    width: 40%;
  }
}

.im-checkout-btn.btn--default {
  border: none !important;
  color: white !important;
  background: #37a000 !important;
  border-radius: 4px !important;
  padding: 8px 16px !important;
  cursor: pointer !important;
  margin: 0 4px !important;
  font-size: 13px !important;
  display: inline-block !important;
}

.no-scroll {
  overflow: hidden;
}

.pop-up {
  position: fixed;
  z-index: 1000;
  top: 0;
  bottom: 0;
  background: #fff;
  overflow-y: scroll;
}
