.container {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #000;
  color: #fff;
  padding: 8px 4px;
  transition: height 0.3s ease-out;
  text-align: center;
}
.container.close {
  height: 0;
  padding: 0;
}
